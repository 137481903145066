<template>
  <v-btn
    :fab="fab"
    :loading="config.loading"
    :class="config.class"
    :title="config.title"
    :color="config.color"
    :disabled="(config.disabled === undefined && disabled) || config.disabled"
    outlined
    x-small
    @click="$emit('click')"
  >
    <v-icon v-if="config.icon || icon" small>{{ config.icon || icon }}</v-icon>
    <span v-else>{{ config.label }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: false
    },
    icon: {
      required: false
    },
    disabled: {
      required: false
    }
  },
  computed: {
    fab() {
      return this.config.loading;
    }
  }
};
</script>
