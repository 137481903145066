<template>
  <div class="caption">
    <v-alert outlined class="caption ma-5" close-text="Close Alert" dismissible v-if="savedOrderLoaded" small>
      <v-icon small left>{{ itemStatus.saved.icon }}</v-icon
      >Zum Ihren Benutzern sind folgende Bestellungen gespeichert
    </v-alert>

    <v-data-table
      show-expand
      :headers="headers"
      :items="items"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      item-key="uuid"
      class="elevation-1 ma-5"
      hide-default-footer
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          v-if="isInProcessing(item)"
          text
          x-small
          class="white--text"
          fab
          :color="itemStatus[item.status].color"
          :loading="true"
        ></v-btn>
        <v-icon v-else small :color="itemStatus[item.status].color">{{ itemStatus[item.status].icon }}</v-icon>
        <span class="caption grey--text mx-1">{{ itemStatus[item.status].label }}</span>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span class="caption" :title="item.name">{{ item.name }}</span>
      </template>

      <template v-slot:[`item.orderArea`]="{ item }">
        <span class="caption">{{ getOrderAreas(item.orderArea) }}</span>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon color="geoportal" @click="expand(true)" v-if="item.features.length > 0 && !isExpanded"
          >mdi-chevron-down</v-icon
        >
        <v-icon color="geoportal" @click="expand(false)" v-if="isExpanded">mdi-chevron-up</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <li class="caption hidden-xs-only list-type-none">
            <ul v-for="feature in item.features" :key="feature.id">
              {{
                feature.title
              }}
            </ul>
          </li>
        </td>
      </template>

      <template v-slot:[`item.downloadSize`]="{ item }">
        <div class="mr-5" v-if="!item.downloadSize && isInProcessing(item)">
          <v-progress-linear
            :color="itemStatus[item.status].color"
            height="10"
            value="10"
            striped
            indeterminate
          ></v-progress-linear>
        </div>
        <span class="caption" v-else>{{ item.downloadSize }}</span>
      </template>

      <template v-slot:[`item.format`]="{ item }">
        <span class="caption">{{ item.format }}</span>
      </template>

      <template v-slot:[`item.manage`]="{ item }">
        <v-btn-action v-if="isInProcessing(item)" :config="action.cancel" @click="abort(item)"></v-btn-action>
        <template v-else>
          <v-btn-action :config="action[itemStatus[item.status].action]" @click="dispatch(item)"></v-btn-action>
          <v-btn-action
            :config="action.copy"
            :disabled="!item.result_file"
            :icon="item.copyIcon"
            @click="copy(item)"
          ></v-btn-action>
          <v-btn-action :config="action.delete" @click="deleteOrder(item)"></v-btn-action>
        </template>
      </template>
      <template slot="no-data"> Keine Daten verfügbar </template>
    </v-data-table>

    <div class="d-flex flex-row justify-start mb-6">
      <v-btn :disabled="getLength" small title="speichern" color="geoportal" class="white--text ma-5" @click="save"
        >Bestellung speichern</v-btn
      >
    </div>
    <the-snackbar />
  </div>
</template>
<script>
import { status, order_action, table_headers } from '@/config';

import TheSnackbar from '@/components/TheSnackbar.vue';
import VBtnAction from '@/components/VBtnAction.vue';
export default {
  components: {
    TheSnackbar,
    VBtnAction
  },
  data() {
    return {
      snackbar: false,
      loading: false,
      singleExpand: false,
      expanded: [],
      action: order_action,
      itemStatus: status,
      headers: table_headers
    };
  },

  computed: {
    getLength() {
      return !this.$store.state.order.orders.length > 0;
    },
    items() {
      return this.$store.state.order.orders;
    },
    savedOrderLoaded() {
      return this.$store.state.order.savedOrderLoaded;
    }
  },
  created() {
    let userSavedOrders = this.getSavedLocalStorageOrdersByUid();
    if (!!userSavedOrders && !this.savedOrderLoaded) {
      this.$store.commit('order/SET_SAVED_ORDERS', userSavedOrders);
      this.$store.commit('order/SAVED_ORDER_LOADED', true);
    }
  },
  methods: {
    getOrderAreas(orderAreas) {
      const areas = orderAreas.split(',');
      if (areas.length > 1) {
        const msg = areas.length - 2 > 0 ? ` (+${areas.length - 2}) weitere` : '';
        return areas.slice(0, 2).join(' ') + msg;
      }
      return orderAreas;
    },
    dispatch(item) {
      if (item.status === this.itemStatus.ready.name) {
        this.download(item);
      } else {
        //empty, saved,abort, error, delete
        item.status = 'new'; // make commit

        this.reorder(item);
      }
    },
    isInProcessing(item) {
      let { status: itemStatus } = item;
      return itemStatus === status.new.name || itemStatus === status.processing.name;
    },
    getUserId() {
      return this.$store.state.auth.user && this.$store.state.auth.user.userId;
    },
    getSavedLocalStorageOrdersByUid() {
      let uid = this.getUserId();
      let userSavedOrders = JSON.parse(localStorage.getItem(uid));
      return userSavedOrders;
    },

    save() {
      const uid = this.getUserId();
      const localStorageData = this.items.map(item => ({
        ...item,
        status: status.saved.name
      }));
      localStorage.setItem(uid, JSON.stringify(localStorageData));

      this.$store.commit('notifications/SET_SNACKBAR', {
        text: 'Bestellung erfolgreich gespeichert !',
        color: 'success'
      });
      this.$store.commit('order/SAVED_ORDER_LOADED', true);
      this.snackbar = true;
    },
    copy(item) {
      item.copyIcon = 'mdi-check';
      setTimeout(() => (item.copyIcon = 'mdi-content-copy'), 2000);
      const str = item.result_file;
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$store.commit('notifications/SET_SNACKBAR', {
        text: 'Downloadlink kopiert!',
        color: 'success'
      });
      this.snackbar = true;
    },
    deleteOrder(item) {
      this.$store.dispatch('order/delete', item.uuid);
    },
    async abort(item) {
      await this.$store.dispatch('order/abort', item.uuid);
    },
    async reorder(item) {
      this.action.cancel.loading = true;
      await this.$store.dispatch('order/reorder', item.uuid);
      this.action.cancel.loading = false;
    },
    download(item) {
      if (!item.result_file) {
        throw new Exception('download link is empty !!!');
      }
      window.location.assign(item.result_file);
    }
  }
};
</script>

<style scoped>
.error-button {
  background-color: red;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.list-type-none {
  list-style-type: none;
}
.local-storage-data {
  border: 1px solid black;
}
</style>
